"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Observable_1 = require("../Observable");
var Subscription_1 = require("../Subscription");
function schedulePromise(input, scheduler) {
  return new Observable_1.Observable(function (subscriber) {
    var sub = new Subscription_1.Subscription();
    sub.add(scheduler.schedule(function () {
      return input.then(function (value) {
        sub.add(scheduler.schedule(function () {
          subscriber.next(value);
          sub.add(scheduler.schedule(function () {
            return subscriber.complete();
          }));
        }));
      }, function (err) {
        sub.add(scheduler.schedule(function () {
          return subscriber.error(err);
        }));
      });
    }));
    return sub;
  });
}
exports.schedulePromise = schedulePromise;
