/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigationMiner: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Fazendas, transportadoras, ...',
        type : 'group',
        icon : 'heroicons_outline:finger-print',
        children : [
            {
                id   : 'cadastro.usina',
                title: 'Cadastro de usina',
                type : 'group',
                icon : 'heroicons_outline:factory',
                children: [
                    {
                        id   : 'usina.regional',
                        title: 'Usina',
                        type : 'basic',
                        icon : 'mat_outline:house_siding',
                        link : '/cadastro/usina'
                    }
                ]
            },
            {
                id   : 'cadastro.receiver',
                title: 'Cadastro de destinatários',
                type : 'group',
                icon : 'heroicons_outline:office-building',
                children: [
                    {
                        id   : 'embarcadora.destinatarios',
                        title: 'Destinatários',
                        type : 'basic',
                        icon : 'mat_outline:maps_home_work',
                        link : '/cadastro/destinatario'
                    }
                ]
            },
            {
                id   : 'cadastro.transportadora',
                title: 'Cadastro de transportadora',
                type : 'group',
                icon : 'mat_outline:local_shipping',
                children: [
                    {
                        id   : 'transportadora',
                        title: 'Transportadora',
                        type : 'basic',
                        icon : 'mat_outline:local_shipping',
                        link : '/cadastro/transportadora-virtual'
                    }
                ]
            }
        ]
    },
    {
        id   : 'manager',
        title: 'Frete',
        subtitle: 'Gerenciamento de frete',
        type : 'group',
        icon : 'heroicons_outline:cog',
        children : [
            {
                id   : 'order',
                title: 'Frete',
                subtitle: 'Solicitação de frete',
                type : 'group',
                icon : 'heroicons_outline:clipboard-check',
                children: [
                    {
                        id   : 'order.master',
                        title: 'Abertura lote',
                        type : 'basic',
                        icon : 'heroicons_outline:document-duplicate',
                        link : '/order/lote'
                    },
                    {
                        id   : 'order.view-frete',
                        title: 'Acompanhamento de frete',
                        type : 'basic',
                        icon : 'mat_outline:dvr',
                        link : '/leilao/embarcador'
                    }

                ]
            }
        ]
    }

];
export const compactNavigationMiner: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Fazendas, transportadoras, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'manager',
        title: 'Frete',
        subtitle: 'Gerenciamento de frete',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
export const futuristicNavigationMiner: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Fazendas, transportadoras, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'manager',
        title: 'Frete',
        subtitle: 'Gerenciamento de frete',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
export const horizontalNavigationMiner: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Fazendas, transportadoras, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'manager',
        title: 'Frete',
        subtitle: 'Gerenciamento de frete',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
