/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigationCarrier: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Motoristas, veículos, ...',
        type : 'group',
        icon : 'heroicons_outline:finger-print',
        children : [
            {
                id   : 'motorista',
                title: 'Cadastro de motorista',
                type : 'basic',
                icon : 'mat_outline:airline_seat_recline_normal',
                link : '/cadastro/motorista'
            },
            {
                id   : 'veiculo',
                title: 'Cadastro de veiculo',
                type : 'basic',
                icon : 'mat_outline:directions_bus',
                link : '/cadastro/veiculo'
            },
            {
                id   : 'frete',
                title: 'Cadastro de frete',
                type : 'basic',
                icon : 'mat_outline:alt_route',
                link : '/cadastro/tabela-frete'
            }
        ]
    },
    {
        id   : 'manager',
        title: 'Gerenciamento',
        subtitle: 'Gerenciamento de solicitações',
        type : 'group',
        icon : 'heroicons_outline:cog',
        children : [
            {
                id   : 'view',
                title: 'Acompanhamento',
                subtitle: 'Acompanhamento de solicitações',
                type : 'group',
                icon : 'mat_outline:dvr',
                children: [
                    {
                        id   : 'leilao',
                        title: 'Leilão de frete',
                        type : 'basic',
                        icon : 'mat_outline:dvr',
                        link : '/leilao/transportador'
                    }
                ]
            },
        ]
    }
];
export const compactNavigationCarrier: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Motoristas, veículos, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'manager',
        title: 'Gerenciamento',
        subtitle: 'Gerenciamento de solicitações',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
export const futuristicNavigationCarrier: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Motoristas, veículos, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'manager',
        title: 'Gerenciamento',
        subtitle: 'Gerenciamento de solicitações',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
export const horizontalNavigationCarrier: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Motoristas, veículos, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'manager',
        title: 'Gerenciamento',
        subtitle: 'Gerenciamento de solicitações',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
