/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigationAdmin: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Embarcadoras, usuários, ...',
        type : 'group',
        icon : 'heroicons_outline:finger-print',
        children : [
            {
                id   : 'cadastro.usuarios',
                title: 'Cadastro de usuários',
                type : 'basic',
                icon : 'heroicons_outline:users',
                link : '/cadastro/usuario'
            },
            {
                id   : 'cadastro.embarcadora',
                title: 'Cadastro de embarcadora',
                type : 'group',
                icon : 'heroicons_outline:office-building',
                children: [
                    {
                        id   : 'embarcadora',
                        title: 'Embarcadora',
                        type : 'basic',
                        icon : 'heroicons_outline:office-building',
                        link : '/cadastro/embarcadora'
                    }
                ]
            }
        ]
    }

];
export const compactNavigationAdmin: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Embarcadoras, usuários, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    }
];
export const futuristicNavigationAdmin: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Embarcadoras, usuários, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    }
];
export const horizontalNavigationAdmin: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Embarcadoras, usuários, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    }
];
