import { inject } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';

export const AuthInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {

    const authService = inject(AuthService);

    let newReq = req.clone();
    if (
        !req.url.includes('assets') &&
        !req.url.includes('ibge') &&
        !req.url.includes('oauth') &&
        !req.url.includes('viacep') &&
        !req.url.includes('nominatim') &&
        !req.url.includes('openstreetmap') &&
        !req.url.includes('s3') &&
        !req.url.includes('reset-password') &&
        !req.url.includes('mapbox'))
    {
        if (authService.accessToken && !AuthUtils.isTokenExpired(authService.accessToken)) {
            newReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken) });
        }
        if (req.url.includes('uploads')) {
            newReq = req.clone({
                headers: req.headers.set('Content-type', '; charset=utf-8; boundary=' + Math.random().toString())
            });
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken)
            });
        }
    }
    return next(newReq).pipe(
        catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                authService.signOut();
                location.reload();
            }
            return throwError(error);
        })
    );

}