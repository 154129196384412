import { Injectable } from "@angular/core";
import { FuseMockApiService } from "@fuse/lib/mock-api";
import { cloneDeep } from "lodash";
import { RASTREADORES } from "./data";


@Injectable({
    providedIn: 'root'
})
export class RastreadoresModeloApi
{
    private _rastreadores: any = RASTREADORES;

    constructor( private _fuseMockApiService: FuseMockApiService )
    {
        this.registerHandlers();
    }

    registerHandlers(): void {

        this._fuseMockApiService.onGet('api/common/veiculo/rastreadores').reply(() => [200, cloneDeep(this._rastreadores)]);

    }
}