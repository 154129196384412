<!-- Navigation -->
@if(isScreenSmall) {
    <ng-container>
        <fuse-vertical-navigation
            class="dark bg-gray-900 print:hidden"
            [mode]="'over'"
            [name]="'mainNavigation'"
            [navigation]="navigation?.default"
            [opened]="false">
            <!-- Navigation header hook -->
            <ng-container fuseVerticalNavigationContentHeader>
                <!-- Logo -->
                <div class="flex justify-center items-center h-25 pt-6 px-8 cursor-pointer" (click)="goToMain()">
                    <img class="w-24" src="assets/images/logo/sbrcargo_120-dark.svg" alt="Logo PickingUP">
                </div>
            </ng-container>
        </fuse-vertical-navigation>
    </ng-container>
}

<!-- Wrapper -->
<div class="relative flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        @if(!isScreenSmall) {
            <ng-container>
                <!-- Logo -->
                <div class="flex items-center mx-2 lg:mr-8 cursor-pointer" (click)="goToMain()">
                    <div class="hidden lg:flex">
                        <img class="dark:hidden w-15" src="assets/images/logo/sbrcargo_120.svg">
                        <img class="hidden dark:flex w-15" src="assets/images/logo/sbrcargo_120-dark.svg">
                    </div>
                    <img class="flex lg:hidden w-8" src="assets/images/logo/sbrcargo_120.svg">
                </div>
                <!-- Horizontal navigation -->
                <fuse-horizontal-navigation
                    class="mr-2"
                    [name]="'mainNavigation'"
                    [navigation]="navigation?.horizontal"></fuse-horizontal-navigation>
            </ng-container>
        }
        <!-- Navigation toggle button -->
        @if(isScreenSmall) {
            <ng-container>
                <button
                    mat-icon-button
                    (click)="toggleNavigation('mainNavigation')">
                    <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                </button>
            </ng-container>
        }
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <button mat-icon-button (click)="goToMain()" matTooltip="Dashboard">
                <mat-icon>dashboard</mat-icon>
            </button> 
            <sbrmodulos></sbrmodulos>
            <!-- <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>-->
            <!-- <messages></messages>  -->
            <notifications></notifications>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="relative flex flex-col flex-auto w-full min-w-0">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if(true) { <router-outlet></router-outlet> }
    </div>

    
    <!-- Footer -->
    <!-- <div class="flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">SBR CARGO &copy; {{currentYear}}</span>
    </div> -->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->

