import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from 'app/mock-api/common/navigation/data';
import { compactNavigationAdmin, defaultNavigationAdmin, futuristicNavigationAdmin, horizontalNavigationAdmin } from 'app/mock-api/common/navigation/dataAdmin';
import { compactNavigationShipment, defaultNavigationShipment, futuristicNavigationShipment, horizontalNavigationShipment } from 'app/mock-api/common/navigation/dataEmbarcadora';
import { compactNavigationViewerShipment, defaultNavigationViewerShipment, futuristicNavigationViewerShipment, horizontalNavigationViewerShipment,  } from 'app/mock-api/common/navigation/dataViewerEmbarcadora';
import { compactNavigationCarrier, defaultNavigationCarrier, futuristicNavigationCarrier, horizontalNavigationCarrier } from 'app/mock-api/common/navigation/dataTransportadora';
import { compactNavigationUser, defaultNavigationUser, futuristicNavigationUser, horizontalNavigationUser } from './dataUser';
import { compactNavigationMiner, defaultNavigationMiner, futuristicNavigationMiner, horizontalNavigationMiner } from 'app/mock-api/common/navigation/dataMineradora';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi
{
    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    private readonly _compactNavigationAdmin: FuseNavigationItem[] = compactNavigationAdmin;
    private readonly _defaultNavigationAdmin: FuseNavigationItem[] = defaultNavigationAdmin;
    private readonly _futuristicNavigationAdmin: FuseNavigationItem[] = futuristicNavigationAdmin;
    private readonly _horizontalNavigationAdmin: FuseNavigationItem[] = horizontalNavigationAdmin;

    private readonly _compactNavigationShipment: FuseNavigationItem[] = compactNavigationShipment;
    private readonly _defaultNavigationShipment: FuseNavigationItem[] = defaultNavigationShipment;
    private readonly _futuristicNavigationShipment: FuseNavigationItem[] = futuristicNavigationShipment;
    private readonly _horizontalNavigationShipment: FuseNavigationItem[] = horizontalNavigationShipment;

    private readonly _compactNavigationViewerShipment: FuseNavigationItem[] = compactNavigationViewerShipment;
    private readonly _defaultNavigationViewerShipment: FuseNavigationItem[] = defaultNavigationViewerShipment;
    private readonly _futuristicNavigationViewerShipment: FuseNavigationItem[] = futuristicNavigationViewerShipment;
    private readonly _horizontalNavigationViewerShipment: FuseNavigationItem[] = horizontalNavigationViewerShipment;

    private readonly _compactNavigationCarrier: FuseNavigationItem[] = compactNavigationCarrier;
    private readonly _defaultNavigationCarrier: FuseNavigationItem[] = defaultNavigationCarrier;
    private readonly _futuristicNavigationCarrier: FuseNavigationItem[] = futuristicNavigationCarrier;
    private readonly _horizontalNavigationCarrier: FuseNavigationItem[] = horizontalNavigationCarrier;

    private readonly _compactNavigationUser: FuseNavigationItem[] = compactNavigationUser;
    private readonly _defaultNavigationUser: FuseNavigationItem[] = defaultNavigationUser;
    private readonly _futuristicNavigationUser: FuseNavigationItem[] = futuristicNavigationUser;
    private readonly _horizontalNavigationUser: FuseNavigationItem[] = horizontalNavigationUser;

    private readonly _compactNavigationMiner: FuseNavigationItem[] = compactNavigationMiner;
    private readonly _defaultNavigationMiner: FuseNavigationItem[] = defaultNavigationMiner;
    private readonly _futuristicNavigationMiner: FuseNavigationItem[] = futuristicNavigationMiner;
    private readonly _horizontalNavigationMiner: FuseNavigationItem[] = horizontalNavigationMiner;

    constructor(
        private _fuseMockApiService: FuseMockApiService,
    )
    {
        this.registerHandlers();
    }

    registerHandlers(): void
    {
        this._fuseMockApiService.onGet('api/common/navigation').reply(() => [200,
            {
                compact   : [],
                default   : [],
                futuristic: [],
                horizontal: [],
            }
        ]);

        this._fuseMockApiService.onGet('api/common/navigation/shipment').reply(() => {
                // Fill compact navigation children using the default navigation
                this._compactNavigationShipment.forEach((compactNavItem) => {
                    this._defaultNavigationShipment.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigationShipment.forEach((futuristicNavItem) => {
                    this._defaultNavigationShipment.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigationShipment.forEach((horizontalNavItem) => {
                    this._defaultNavigationShipment.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });
                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this._compactNavigationShipment),
                        default   : cloneDeep(this._defaultNavigationShipment),
                        futuristic: cloneDeep(this._futuristicNavigationShipment),
                        horizontal: cloneDeep(this._horizontalNavigationShipment)
                    }
                ];
        });

        this._fuseMockApiService.onGet('api/common/navigation/shipment-viewer').reply(() => {
                // Fill compact navigation children using the default navigation
                this._compactNavigationViewerShipment.forEach((compactNavItem) => {
                    this._defaultNavigationViewerShipment.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigationViewerShipment.forEach((futuristicNavItem) => {
                    this._defaultNavigationViewerShipment.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigationViewerShipment.forEach((horizontalNavItem) => {
                    this._defaultNavigationViewerShipment.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this._compactNavigationViewerShipment),
                        default   : cloneDeep(this._defaultNavigationViewerShipment),
                        futuristic: cloneDeep(this._futuristicNavigationViewerShipment),
                        horizontal: cloneDeep(this._horizontalNavigationViewerShipment)
                    }
                ];
        });

        this._fuseMockApiService.onGet('api/common/navigation/carrier').reply(() => {
                // Fill compact navigation children using the default navigation
                this._compactNavigationCarrier.forEach((compactNavItem) => {
                    this._defaultNavigationCarrier.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigationCarrier.forEach((futuristicNavItem) => {
                    this._defaultNavigationCarrier.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigationCarrier.forEach((horizontalNavItem) => {
                    this._defaultNavigationCarrier.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this._compactNavigationCarrier),
                        default   : cloneDeep(this._defaultNavigationCarrier),
                        futuristic: cloneDeep(this._futuristicNavigationCarrier),
                        horizontal: cloneDeep(this._horizontalNavigationCarrier)
                    }
                ];
        });

        this._fuseMockApiService.onGet('api/common/navigation/miner').reply(() => {
            // Fill compact navigation children using the default navigation
            this._compactNavigationMiner.forEach((compactNavItem) => {
                this._defaultNavigationMiner.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === compactNavItem.id )
                    {
                        compactNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill futuristic navigation children using the default navigation
            this._futuristicNavigationMiner.forEach((futuristicNavItem) => {
                this._defaultNavigationMiner.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === futuristicNavItem.id )
                    {
                        futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill horizontal navigation children using the default navigation
            this._horizontalNavigationMiner.forEach((horizontalNavItem) => {
                this._defaultNavigationMiner.forEach((defaultNavItem) => {
                    if ( defaultNavItem.id === horizontalNavItem.id )
                    {
                        horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Return the response
            return [
                200,
                {
                    compact   : cloneDeep(this._compactNavigationMiner),
                    default   : cloneDeep(this._defaultNavigationMiner),
                    futuristic: cloneDeep(this._futuristicNavigationMiner),
                    horizontal: cloneDeep(this._horizontalNavigationMiner)
                }
            ];
    });

        this._fuseMockApiService.onGet('api/common/navigation/admin').reply(() => {
                // Fill compact navigation children using the default navigation
                this._compactNavigationAdmin.forEach((compactNavItem) => {
                    this._defaultNavigationAdmin.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigationAdmin.forEach((futuristicNavItem) => {
                    this._defaultNavigationAdmin.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigationAdmin.forEach((horizontalNavItem) => {
                    this._defaultNavigationAdmin.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this._compactNavigationAdmin),
                        default   : cloneDeep(this._defaultNavigationAdmin),
                        futuristic: cloneDeep(this._futuristicNavigationAdmin),
                        horizontal: cloneDeep(this._horizontalNavigationAdmin)
                    }
                ];
        });

        this._fuseMockApiService.onGet('api/common/navigation/user').reply(() => {
                // Fill compact navigation children using the default navigation
                this._compactNavigationUser.forEach((compactNavItem) => {
                    this._defaultNavigationUser.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === compactNavItem.id )
                        {
                            compactNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill futuristic navigation children using the default navigation
                this._futuristicNavigationUser.forEach((futuristicNavItem) => {
                    this._defaultNavigationUser.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === futuristicNavItem.id )
                        {
                            futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Fill horizontal navigation children using the default navigation
                this._horizontalNavigationUser.forEach((horizontalNavItem) => {
                    this._defaultNavigationUser.forEach((defaultNavItem) => {
                        if ( defaultNavItem.id === horizontalNavItem.id )
                        {
                            horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                        }
                    });
                });

                // Return the response
                return [
                    200,
                    {
                        compact   : cloneDeep(this._compactNavigationUser),
                        default   : cloneDeep(this._defaultNavigationUser),
                        futuristic: cloneDeep(this._futuristicNavigationUser),
                        horizontal: cloneDeep(this._horizontalNavigationUser)
                    }
                ];
        });
    }
}
