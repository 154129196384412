/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigationUser: FuseNavigationItem[] = [
    {
        id   : 'controls',
        title: 'Controles',
        subtitle: 'Controles logísticos',
        type : 'group',
        icon : 'mat_outline:admin_panel_settings',
        children : [
            {
                id   : 'gate',
                title: 'Portaria',
                subtitle: 'Controle de portaria',
                type : 'basic',
                icon : 'mat_outline:assistant_direction',
                link: '/controls/portaria'
            }
        ]
    }
];
export const compactNavigationUser: FuseNavigationItem[] = [
    {
        id   : 'controls',
        title: 'Gerenciamento',
        subtitle: 'Controles logísticos',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
export const futuristicNavigationUser: FuseNavigationItem[] = [
    {
        id   : 'controls',
        title: 'Gerenciamento',
        subtitle: 'Controles logísticos',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
export const horizontalNavigationUser: FuseNavigationItem[] = [
    {
        id   : 'controls',
        title: 'Gerenciamento',
        subtitle: 'Controles logísticos',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
