"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Observable_1 = require("../Observable");
var Subscription_1 = require("../Subscription");
var iterator_1 = require("../symbol/iterator");
function scheduleIterable(input, scheduler) {
  if (!input) {
    throw new Error('Iterable cannot be null');
  }
  return new Observable_1.Observable(function (subscriber) {
    var sub = new Subscription_1.Subscription();
    var iterator;
    sub.add(function () {
      if (iterator && typeof iterator.return === 'function') {
        iterator.return();
      }
    });
    sub.add(scheduler.schedule(function () {
      iterator = input[iterator_1.iterator]();
      sub.add(scheduler.schedule(function () {
        if (subscriber.closed) {
          return;
        }
        var value;
        var done;
        try {
          var result = iterator.next();
          value = result.value;
          done = result.done;
        } catch (err) {
          subscriber.error(err);
          return;
        }
        if (done) {
          subscriber.complete();
        } else {
          subscriber.next(value);
          this.schedule();
        }
      }));
    }));
    return sub;
  });
}
exports.scheduleIterable = scheduleIterable;
