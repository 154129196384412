/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateAccessService {

  private api = environment.api;
  private apiAuthShipper = environment.api_shipper;

  private _authService = inject(AuthService);

  constructor(
    private _httpClient: HttpClient
    )
  {}

  authenticateAccess(token: string, bearer: string): any
  {
    const bearerToken = 'Bearer ' + bearer;
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Authorization': bearerToken
    });
    return this._httpClient.get(this.api + `/auth/authenticate-module/${token}`, {headers: header})
    .pipe(
      map(() => {
        AuthUtils.setCookie({name: '_atk_sbrcargo', value: bearer, expireDays: 2});
        AuthUtils.setCookie({name: '_rtk_sbrcargo', value: bearer, expireDays: 2});
        return true;
      }),
      catchError(() => {
        AuthUtils.deleteCookie('_atk_sbrcargo');
        AuthUtils.deleteCookie('_rtk_sbrcargo');
        AuthUtils.deleteCookie('_us_sbrcargo');
        return of(false);
      })
    );
  }

  changeAutenticateCode(keycloakHost: string, code: string, session_state: string): any
  {
    const { origin } = this._authService.extractSubdomain();
    const bodyParams = {
      code: code.toString(),
      redirect_host: `${origin}/authenticate`,
      keycloakUrl: keycloakHost
    };
    return this._httpClient.post(this.apiAuthShipper + '/auth/authorization', bodyParams).pipe(
      map((result: any) => {
        console.log('CHANGE', result);
        AuthUtils.setCookie({name: '_atk_sbrcargo', value: result.access_token});
        AuthUtils.setCookie({name: '_rtk_sbrcargo', value: result.refresh_token});
        const userData = result;
        delete userData.access_token;
        console.log('USER DATA', userData);
        
        AuthUtils.setCookie({name: '_us_sbrcargo', value: JSON.stringify(userData)});
        return result
      }),
      catchError(() => {
        AuthUtils.deleteCookie('_atk_sbrcargo');
        AuthUtils.deleteCookie('_rtk_sbrcargo');
        AuthUtils.deleteCookie('_us_sbrcargo');
        return of(false);
      })
    );
  
  }

}
