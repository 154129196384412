import { AcademyMockApi } from 'app/mock-api/apps/academy/api';
import { ActivitiesMockApi } from 'app/mock-api/pages/activities/api';
import { AnalyticsMockApi } from 'app/mock-api/dashboards/analytics/api';
import { AuthMockApi } from 'app/mock-api/common/auth/api';
import { CalendarMockApi } from 'app/mock-api/apps/calendar/api';
import { ChatMockApi } from 'app/mock-api/apps/chat/api';
import { ContactsMockApi } from 'app/mock-api/apps/contacts/api';
import { CryptoMockApi } from 'app/mock-api/dashboards/crypto/api';
import { ECommerceInventoryMockApi } from 'app/mock-api/apps/ecommerce/inventory/api';
import { FileManagerMockApi } from 'app/mock-api/apps/file-manager/api';
import { FinanceMockApi } from 'app/mock-api/dashboards/finance/api';
import { HelpCenterMockApi } from 'app/mock-api/apps/help-center/api';
import { IconsMockApi } from 'app/mock-api/ui/icons/api';
import { MailboxMockApi } from 'app/mock-api/apps/mailbox/api';
import { MessagesMockApi } from 'app/mock-api/common/messages/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotesMockApi } from 'app/mock-api/apps/notes/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { ProjectMockApi } from 'app/mock-api/dashboards/project/api';
import { SearchMockApi } from 'app/mock-api/common/search/api';
import { ScrumboardMockApi } from 'app/mock-api/apps/scrumboard/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';
import { TasksMockApi } from 'app/mock-api/apps/tasks/api';
import { UserMockApi } from 'app/mock-api/common/user/api';
import { BankApi } from './common/bank/api';
import { UfApi } from './common/estados/api';
import { CNHApi } from './common/cnh/api';
import { CarroceriaApi } from './common/veiculo/carroceria/api';
import { RastreadoresModeloApi } from './common/veiculo/rastreadores/api';
import { ModeloRastreadorModeloApi } from './common/veiculo/modelo-rastreadores/api';
import { FuelApi } from './common/veiculo/fuels/api';
import { IscaTypeApi } from './common/veiculo/isca-type/api';
import { IscasApi } from './common/veiculo/iscas/api';
import { StorageApi } from './common/warehouse/storage/api';
import { ProductApi } from './common/warehouse/product/api';
import { PickingApi } from './common/warehouse/picking/api';
import { OrderApi } from './common/warehouse/order/api';

export const mockApiServices = [
    AcademyMockApi,
    ActivitiesMockApi,
    AnalyticsMockApi,
    AuthMockApi,
    CalendarMockApi,
    ChatMockApi,
    ContactsMockApi,
    CryptoMockApi,
    ECommerceInventoryMockApi,
    FileManagerMockApi,
    FinanceMockApi,
    HelpCenterMockApi,
    IconsMockApi,
    MailboxMockApi,
    MessagesMockApi,
    NavigationMockApi,
    NotesMockApi,
    NotificationsMockApi,
    ProjectMockApi,
    SearchMockApi,
    ScrumboardMockApi,
    ShortcutsMockApi,
    TasksMockApi,
    UserMockApi,
    BankApi,
    UfApi,
    CNHApi,
    CarroceriaApi,
    ModeloRastreadorModeloApi,
    RastreadoresModeloApi,
    FuelApi,
    IscasApi,
    IscaTypeApi,
    StorageApi,
    ProductApi,
    PickingApi,
    OrderApi,
];
