import { Injectable } from "@angular/core";
import { FuseMockApiService, FuseMockApiUtils } from "@fuse/lib/mock-api";
import { assign, cloneDeep } from "lodash";
import { banks } from 'app/mock-api/common/bank/data';

@Injectable({
    providedIn: 'root'
})
export class BankApi
{
    private _banks: any = banks;

    constructor( private _fuseMockApiService: FuseMockApiService )
    {
        this.registerHandlers();
    }

    registerHandlers(): void
    {
        // GET
        this._fuseMockApiService.onGet('api/common/bank').reply(() => [200, cloneDeep(this._banks)]);
        // POST
        this._fuseMockApiService
        .onPost('api/common/bank')
        .reply(({request}) => {
            const newBank = cloneDeep(request.body.message);
            newBank.id = FuseMockApiUtils.guid();
            this._banks.unshift(newBank);
            return [200, newBank];
        });
        // PUT
        this._fuseMockApiService
        .onPatch('api/common/bank')
        .reply(({request}) => {

            // Get the id and message
            const id = request.body.id;
            const bank = cloneDeep(request.body.message);
            // Prepare the updated bank
            let updatedBank = null;
            // Find the bank and update it
            this._banks.forEach((item: any, index: number, banks: any[]) => {
                if ( item.id === id )
                {
                    // Update the bank
                    banks[index] = assign({}, banks[index], bank);
                    // Store the updated bank
                    updatedBank = banks[index];
                }
            });
            return [200, updatedBank];
        });
        // DELETE
        this._fuseMockApiService
        .onDelete('api/common/bank')
        .reply(({request}) => {
            // Get the id
            const id = request.params.get('id');
            // Prepare the deleted bank
            let deletedBank = null;
            // Find the bank
            const index = this._banks.findIndex((item: any) => item.id === id);
            // Store the deleted bank
            deletedBank = cloneDeep(this._banks[index]);
            this._banks.splice(index, 1);
            return [200, deletedBank];
        });
    }
}