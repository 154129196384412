export const analytics = {
    visitors           : {
        series: {
            'this-year': [
                {
                    name: 'Visitors',
                    data: []
                }
            ],
            'last-year': [
                {
                    name: 'Visitors',
                    data: []
                }
            ]
        }
    },
    conversions        : {
        amount: 4123,
        labels: [],
        series: [
            {
                name: 'Conversions',
                data: [4412, 4345, 4541, 4677, 4322, 4123]
            }
        ]
    },
    impressions        : {
        amount: 46085,
        labels: [],
        series: [
            {
                name: 'Impressions',
                data: [11577, 11441, 11544, 11523]
            }
        ]
    },
    visits             : {
        amount: 62083,
        labels: [],
        series: [
            {
                name: 'Visits',
                data: [15521, 15519, 15522, 15521]
            }
        ]
    },
    visitorsVsPageViews: {
        overallScore  : 472,
        averageRatio  : 45,
        predictedRatio: 55,
        series        : [
            {
                name: 'Page Views',
                data: []
            },
            {
                name: 'Visitors',
                data: []
            }
        ]
    },
    newVsReturning     : {
        uniqueVisitors: 46085,
        series        : [80, 20],
        labels        : [
            'New',
            'Returning'
        ]
    },
    gender             : {
        uniqueVisitors: 46085,
        series        : [55, 45],
        labels        : [
            'Male',
            'Female'
        ]
    },
    age                : {
        uniqueVisitors: 46085,
        series        : [35, 65],
        labels        : [
            'Under 30',
            'Over 30'
        ]
    },
    language           : {
        uniqueVisitors: 46085,
        series        : [25, 75],
        labels        : [
            'English',
            'Other'
        ]
    }
};
