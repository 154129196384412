import { Injectable } from "@angular/core";
import { FuseMockApiService } from "@fuse/lib/mock-api";
import { cloneDeep } from "lodash";
import { CARROCERIA } from "./data";

@Injectable({
    providedIn: 'root'
})
export class CarroceriaApi
{
    private _carrocerias: any = CARROCERIA;

    constructor( private _fuseMockApiService: FuseMockApiService )
    {
        this.registerHandlers();
    }

    registerHandlers(): void {

        this._fuseMockApiService.onGet('api/common/veiculo/carroceria').reply(() => [200, cloneDeep(this._carrocerias)]);

    }
}