import { Injectable } from "@angular/core";
import { FuseMockApiService } from "@fuse/lib/mock-api";
import { cloneDeep } from "lodash";
import { MODELO_RASTREADOR } from "./data";

@Injectable({
    providedIn: 'root'
})
export class ModeloRastreadorModeloApi
{
    private _modelos: any = MODELO_RASTREADOR;

    constructor( private _fuseMockApiService: FuseMockApiService )
    {
        this.registerHandlers();
    }

    registerHandlers(): void {

        this._fuseMockApiService.onGet('api/common/veiculo/modelo-rastreadores').reply(() => [200, cloneDeep(this._modelos)]);

    }
}