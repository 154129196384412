import { Injectable } from "@angular/core";
import { FuseMockApiService, FuseMockApiUtils } from "@fuse/lib/mock-api";
import { assign, cloneDeep } from "lodash";
import { PRODUCTS } from "./data";

@Injectable({
    providedIn: 'root'
})
export class ProductApi
{
    private _produtos: any = PRODUCTS;

    constructor( private _fuseMockApiService: FuseMockApiService )
    {
        this.registerHandlers();
    }

    registerHandlers(): void {
        // GET
        this._fuseMockApiService.onGet('api/common/warehouse/product').reply(() => {
            if (localStorage.getItem('products') != undefined) {
                const dt = JSON.parse(localStorage.getItem('products'));
                this._produtos = dt;
            }
            return [200, cloneDeep(this._produtos)];
        });
        // POST
        this._fuseMockApiService.onPost('api/common/warehouse/product').reply(({request}) => {
            const newProduct = cloneDeep(request.body);
            // Generate a new GUID
            // newProduct.id = FuseMockApiUtils.guid();
            let exists = false;
            this._produtos.forEach((item: any, index: number, produtos: any[]) => {
                if ( item.id === newProduct.id) {
                    produtos[index] = assign({}, produtos[index], newProduct);
                    exists = true;
                }
            });
            if (!exists) {
                this._produtos.unshift(newProduct);
            }
            const dt = this._produtos;
            localStorage.setItem('products', JSON.stringify(dt));
            return [200, newProduct];
        });
        // PUT
        this._fuseMockApiService.onPatch('api/common/warehouse/product').reply(({request}) => {
            const id = request.params.get('id');
            const newProduto = cloneDeep(request.body);
            let updatedNotification = null;
            this._produtos.forEach((item: any, index: number, produtos: any[]) => {
                if ( item.id === id) {
                    produtos[index] = assign({}, produtos[index], newProduto);
                    updatedNotification = produtos[index];
                }
            });
            localStorage.setItem('products', JSON.stringify(this._produtos));
            return [200, updatedNotification];
        });
        // PUT
        this._fuseMockApiService.onPatch('api/common/warehouse/product/storage').reply(({request}) => {
            const id = request.body.id;
            const newStorage = cloneDeep(request.body.newStorage);
            console.log(id);
            console.log(newStorage);
            
            let updatedProduto = null;
            this._produtos.forEach((item: any, index: number, produtos: any[]) => {
                if ( item.id === id) {
                    produtos[index].storage = newStorage;
                    updatedProduto = produtos[index];
                }
            });
            localStorage.setItem('products', JSON.stringify(this._produtos));
            return [200, updatedProduto];
        });
        // SEARCH
        this._fuseMockApiService.onPatch('api/common/warehouse/product/search').reply(({request}) => {
            const name = request.body.name;
            const index = this._produtos.findIndex((item: any) => item.name === name);
            if (index > -1) {
                return [200, this._produtos[index]];
            } else {
                return [400, 'Nenhum produto encontrado'];
            }
        });
        // DELETE
        this._fuseMockApiService.onDelete('api/common/warehouse/product').reply(({request}) => {
            const id = request.params.get('id');
            let deletedStorage = null;
            const index = this._produtos.findIndex((item: any) => item.id === id);
            deletedStorage = cloneDeep(this._produtos[index]);
            this._produtos.splice(index, 1);
            localStorage.setItem('products', JSON.stringify(this._produtos));
            return [200, deletedStorage];
        });

    }
}