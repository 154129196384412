import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleEmbarcadorGuard  {

  constructor(
    private _userService: UserService,
    private _router: Router
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
      const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
      this._userService.recoveryUserSession();
      return this._userService.user$
      .pipe(
        switchMap((res: any) => {
          if (res) {
            const userType = res.company.clientType;
            
            if (userType === 'TRANSPORTADOR') {
              this._router.navigate(['carrier-grid']);
              return of(false);
            } else if (['SHIPPER', 'SHIPPER_REG', 'SHIPPER_REG_FIL', 'SHIPPER_FIL'].includes(userType)) {
              const rolePermissions = res.roles;
              if (!rolePermissions.includes('sbrcargo_admin_access')) {
                this._router.navigate(['waypoint-grid']);
                return of(false);
              }
              return of(true);
            } else if (userType === 'JODAX') {
              this._router.navigate(['admin']);
              return of(false);
            }
            return of(false);
          } else {
            this._router.navigate(['sign-in'], {queryParams: {redirectUrl}});
          }
        })
      );
  }
}
