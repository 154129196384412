import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateAccessGuard  {

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
    if(
      (route.queryParams.code && route.queryParams.code !== undefined) &&
      (route.queryParams.session_state && route.queryParams.session_state !== undefined)
    ) {
      return true;
    }
    return false;
  }
}
