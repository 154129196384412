/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigationShipment: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Fazendas, transportadoras, ...',
        type : 'group',
        icon : 'heroicons_outline:finger-print',
        children : [
            {
                id   : 'cadastro.fornecedor',
                title: 'Cadastro de fornecedor',
                type : 'group',
                icon : 'mat_outline:maps_home_work',
                children: [
                    {
                        id   : 'fornecedor.regional',
                        title: 'Fornecedor',
                        type : 'basic',
                        icon : 'mat_outline:house_siding',
                        link : '/cadastro/fornecedor'
                    }
                ]
            },
            {
                id   : 'cadastro.embarcadora',
                title: 'Cadastro de embarcadora, fornecedor e destinatários',
                type : 'group',
                icon : 'heroicons_outline:office-building',
                children: [
                    {
                        id   : 'embarcadora.regional',
                        title: 'Embarcadora regional/fornecedor',
                        type : 'basic',
                        icon : 'mat_outline:house_siding',
                        link : '/cadastro/embarcadora-regional'
                    },
                    {
                        id   : 'embarcadora.destinatarios',
                        title: 'Destinatários',
                        type : 'basic',
                        icon : 'mat_outline:maps_home_work',
                        link : '/cadastro/destinatario'
                    }
                ]
            },
            {
                id   : 'cadastro.transportadora',
                title: 'Cadastro de transportadora',
                type : 'group',
                icon : 'mat_outline:local_shipping',
                children: [
                    {
                        id   : 'transportadora',
                        title: 'Transportadora',
                        type : 'basic',
                        icon : 'mat_outline:local_shipping',
                        link : '/cadastro/transportadora'
                    }
                ]
            },
            {
                id   : 'cadastro.contrato',
                title: 'Cadastro de contrato de frete',
                type : 'basic',
                icon : 'mat_outline:history_edu',
                link : '/cadastro/contrato-de-frete'
            }
        ]
    },
    {
        id   : 'manager',
        title: 'Frete',
        subtitle: 'Gerenciamento de frete',
        type : 'group',
        icon : 'heroicons_outline:cog',
        children : [
            {
                id   : 'order',
                title: 'Frete',
                subtitle: 'Solicitação de frete',
                type : 'group',
                icon : 'heroicons_outline:clipboard-check',
                children: [
                    {
                        id   : 'order.master',
                        title: 'Abertura lote',
                        type : 'basic',
                        icon : 'heroicons_outline:document-duplicate',
                        link : '/order/lote'
                    },
                    {
                        id   : 'order.quote',
                        title: 'Cotação de frete',
                        type : 'basic',
                        icon : 'mat_outline:request_quote',
                        link : '/quotes/multiple-quotes'
                    },
                    {
                        id   : 'order.view-frete',
                        title: 'Acompanhamento de frete',
                        type : 'basic',
                        icon : 'mat_outline:dvr',
                        link : '/leilao/embarcador'
                    }

                ]
            },
            {
                id   : 'uploads',
                title: 'Upload',
                subtitle: 'Envio de arquivos de retorno',
                type : 'group',
                icon : 'mat_outline:cloud_upload',
                children: [
                    {
                        id   : 'nfe',
                        title: 'Upload',
                        subtitle: 'Upload de xml de NFe',
                        type : 'basic',
                        icon : 'mat_outline:cloud_upload',
                        link : '/processing/nfe'
                    },
                    {
                        id   : 'sheetrt',
                        title: 'Upload FIAGRIL',
                        subtitle: 'Upload de planilha de retorno',
                        type : 'basic',
                        icon : 'mat_outline:cloud_upload',
                        link : '/processing/sheet'
                    }
                ]
            }
        ]
    }

];
export const compactNavigationShipment: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Fazendas, transportadoras, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'manager',
        title: 'Frete',
        subtitle: 'Gerenciamento de frete',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
export const futuristicNavigationShipment: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Fazendas, transportadoras, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'manager',
        title: 'Frete',
        subtitle: 'Gerenciamento de frete',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
export const horizontalNavigationShipment: FuseNavigationItem[] = [
    {
        id   : 'cadastro',
        title: 'Cadastro',
        subtitle: 'Fazendas, transportadoras, ...',
        type : 'aside',
        icon : 'heroicons_outline:finger-print',
        children : []
    },
    {
        id   : 'manager',
        title: 'Frete',
        subtitle: 'Gerenciamento de frete',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
