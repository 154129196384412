import { inject } from '@angular/core';
import { forkJoin } from 'rxjs';
import { NotificationService } from './core/notification/notification.service';

export const initialDataResolver = () => {

    const notificationsService = inject(NotificationService);
    
    return forkJoin([
        notificationsService.getAll()
    ]);
};
