export const CARROCERIA = [
    "NÃO SE APLICA",
    "BASCULHANTE",
    "BAÚ",
    "BAÚ FRIGORIFÍCO",
    "BOIADEIRO",
    "CARGA SECA",
    "CARROCERIA ABERTA",
    "CARROCERIA FECHADA",
    "CAÇAMBA",
    "CEGONHEIRO",
    "COMBOIO",
    "DOLLY",
    "FURGÃO",
    "GAIOLA",
    "MECÂNICO OPERACIONAL",
    "PLATAFORMA",
    "PRANCHA",
    "ROLL-ON ROLL-OFF",
    "SIDER",
    "SILO",
    "TANQUE",
    "TANQUE QUÍMICO",
    "TORA DE MADEIRA",
    "TRANSPORTE DE ANIMAL",
    "TRANSPORTE DE VALORES",
    "TRANSPORTE DE GRANITO",
    "OUTROS"
];