"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var iterator_1 = require("../symbol/iterator");
function isIterable(input) {
  return input && typeof input[iterator_1.iterator] === 'function';
}
exports.isIterable = isIterable;
