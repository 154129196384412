/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-len */
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthenticateAccessGuard } from './modules/auth/authenticate-access/authenticate-access.guard';
import { AuthenticateAccessResolver } from './modules/auth/authenticate-access/authenticate-access.resolver';
import { RoleTransportadorGuard } from './core/auth/guards/role-transportador.guard';
import { RoleEmbarcadorGuard } from './core/auth/guards/role-embarcador.guard';
import { TravelsGuard } from './modules/pages/travels/travels.guard';
import { AuthAdminGuard } from './core/auth/guards/auth-admin.guard';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'home' },
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },
    { path: 'signed-in-redirect-motorista', pathMatch: 'full', redirectTo: 'motorista/grid' },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routing') },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routing') },
            { path: 'reset-password/:token', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routing') },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routing') },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routing')}
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routing') },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routing') }
        ]
    },
    {
        path: 'authenticate',
        canActivate: [AuthenticateAccessGuard],
        resolve: { initialData: AuthenticateAccessResolver },
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: '', loadChildren: () => import('app/modules/auth/authenticate-access/authenticate-access-routing.module') },
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        data: { layout: 'empty' },
        children: [
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routing') },
        ]
    },
    {
        path: 'motorista',
        component: LayoutComponent,
        data: { layout: 'empty' },
        children: [
            { path: '', loadChildren: () => import('app/modules/pages/mobile/mobile-routing.module') },
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard, RoleEmbarcadorGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            { path: 'dashboard', loadChildren: () => import('app/modules/pages/embarcador-dashboard/embarcador-dashboard-routing.module') },
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard, RoleTransportadorGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            { path: 'carrier-grid', loadChildren: () => import('app/modules/pages/dashboard/dashboard-routing.module') },
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard, TravelsGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            { path: 'waypoint-grid', loadChildren: () => import('app/modules/pages/travels/travels-routing.module') },
        ]
    },
    {
        path: 'cadastro',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        // resolve: {
        //     initialData: initialDataResolver,
        // },
        children: [
            { path: 'usina', loadChildren: () => import('app/modules/pages/cadastros/mineradora/usinas/usina-routing.module') },
            { path: 'usuario', loadChildren: () => import('app/modules/pages/cadastros/usuarios/cadastro-usuarios.routing') },
            { path: 'embarcadora', canActivate: [AuthAdminGuard], loadChildren: () => import('app/modules/pages/cadastros/embarcadora/embarcadora-routing.module') },
            { path: 'fornecedor', loadChildren: () => import('app/modules/pages/cadastros/fornecedores/fornecedor-routing.module') },
            { path: 'mineradora', loadChildren: () => import('app/modules/pages/cadastros/mineradora/mina/mina-routing.module') },
            { path: 'embarcadora-regional', loadChildren: () => import('app/modules/pages/cadastros/embarcadora-regional/embarcadora-regional-routing.module') },
            { path: 'embarcadora-filial', loadChildren: () => import('app/modules/pages/cadastros/embarcadora-filial/embarcadora-filial-routing.module') },
            { path: 'embarcadora-fazenda', loadChildren: () => import('app/modules/pages/cadastros/embarcadora-fazenda/embarcadora-fazenda-routing.module') },
            { path: 'destinatario', loadChildren: () => import('app/modules/pages/cadastros/destinatarios/destinatarios-routing.module') },
            { path: 'transportadora', loadChildren: () => import('app/modules/pages/cadastros/transportadora/transportadora-routing.module') },
            { path: 'transportadora-virtual', loadChildren: () => import('app/modules/pages/cadastros/transportadora_virtual/transportadora-virtual-routing.module') },
            { path: 'motorista', loadChildren: () => import('app/modules/pages/cadastros/motorista/motorista-routing.module') },
            { path: 'veiculo', loadChildren: () => import('app/modules/pages/cadastros/veiculos/veiculo-routing.module') },
            { path: 'tabela-frete', loadChildren: () => import('app/modules/pages/cadastros/tabela-frete/tabela-frete-routing.module') },
            { path: 'contrato-de-frete', loadChildren: () => import('app/modules/pages/contract/contract-routing.module') },
        ]
    },
    {
        path: 'order',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            { path: 'lote', loadChildren: () => import('app/modules/pages/solicitacao/master-order/master-order-routing.module') },
            { path: 'frete', loadChildren: () => import('app/modules/pages/solicitacao/frete/frete-routing.module') },
            { path: 'dispatch-freight', loadChildren: () => import('app/modules/pages/solicitacao/dispatch-freight/dispatch-freight-routing.module') },
            { path: 'dashboard', loadChildren: () => import('app/modules/pages/acompanhamento/view-frete/view-frete-routing.module') },
        ]
    },
    {
        path: 'processing',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            { path: 'nfe', loadChildren: () => import('app/modules/pages/process-nfe/process-nfe-routing.module') },
            { path: 'sheet', loadChildren: () => import('app/modules/pages/process-sheet-return/process-sheet-return-routing.module') },
        ]
    },
    {
        path: 'leilao',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            { path: 'transportador', loadChildren: () => import('app/modules/pages/transportador/leilao/leilao-transportador-routing.module') },
            { path: 'embarcador', loadChildren: () => import('app/modules/pages/embarcador/leilao/leilao-embarcador-routing.module') },
        ]
    },
    {
        path: 'quotes',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            { path: 'multiple-quotes', loadChildren: () => import('app/modules/pages/multiple-quotes/multiple-quotes-routing.module') },
        ]
    },
    {
        path: 'controls',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            { path: 'portaria', loadChildren: () => import('app/modules/pages/portaria/portaria-routing.module') },
        ]
    },
    {
        path: 'admin',
        canActivate: [AuthGuard, AuthAdminGuard],
        component: LayoutComponent,
        // resolve    : {
        //     initialData: initialDataResolver,
        // },
        // children   : [
        //     {path: 'dashboard', loadChildren: () => import('app/modules/pages/embarcador-dashboard/embarcador-dashboard-routing.module')},
        // ]
    },
    {
        path: 'policy',
        component  : LayoutComponent,
        data: { layout: 'empty'},
        children   : [
            {path: '', loadChildren: () => import('app/modules/landing/politica/politica.routing')},
        ]
    },
    {
        path: 'terms',
        component  : LayoutComponent,
        data: { layout: 'empty'},
        children   : [
            {path: '', loadChildren: () => import('app/modules/landing/termos/termos.routing')},
        ]
    },
];
