import { Bank } from "app/core/bank/bank.types";

export const banks: Bank[] = [
    {
        "id": 1,
        "codigo": "654",
        "banco": "Banco A.J.Renner S.A."
    },
    {
        "id": 2,
        "codigo": "246",
        "banco": "Banco ABC Brasil S.A."
    },
    {
        "id": 3,
        "codigo": "025",
        "banco": "Banco Alfa S.A."
    },
    {
        "id": 4,
        "codigo": "641",
        "banco": "Banco Alvorada S.A."
    },
    {
        "id": 5,
        "codigo": "213",
        "banco": "Banco Arbi S.A."
    },
    {
        "id": 6,
        "codigo": "019",
        "banco": "Banco Azteca do Brasil S.A."
    },
    {
        "id": 7,
        "codigo": "029",
        "banco": "Banco Banerj S.A."
    },
    {
        "id": 8,
        "codigo": "000",
        "banco": "Banco Bankpar S.A."
    },
    {
        "id": 9,
        "codigo": "740",
        "banco": "Banco Barclays S.A."
    },
    {
        "id": 10,
        "codigo": "107",
        "banco": "Banco BBM S.A."
    },
    {
        "id": 11,
        "codigo": "031",
        "banco": "Banco Beg S.A."
    },
    {
        "id": 12,
        "codigo": "739",
        "banco": "Banco BGN S.A."
    },
    {
        "id": 13,
        "codigo": "096",
        "banco": "Banco BM&F de Serviços de Liquidação e Custódia S.A"
    },
    {
        "id": 14,
        "codigo": "318",
        "banco": "Banco BMG S.A."
    },
    {
        "id": 15,
        "codigo": "752",
        "banco": "Banco BNP Paribas Brasil S.A."
    },
    {
        "id": 16,
        "codigo": "248",
        "banco": "Banco Boavista Interatlântico S.A."
    },
    {
        "id": 17,
        "codigo": "218",
        "banco": "Banco Bonsucesso S.A."
    },
    {
        "id": 18,
        "codigo": "065",
        "banco": "Banco Bracce S.A."
    },
    {
        "id": 19,
        "codigo": "036",
        "banco": "Banco Bradesco BBI S.A."
    },
    {
        "id": 20,
        "codigo": "204",
        "banco": "Banco Bradesco Cartões S.A."
    },
    {
        "id": 21,
        "codigo": "394",
        "banco": "Banco Bradesco Financiamentos S.A."
    },
    {
        "id": 22,
        "codigo": "237",
        "banco": "Banco Bradesco S.A."
    },
    {
        "id": 23,
        "codigo": "225",
        "banco": "Banco Brascan S.A."
    },
    {
        "id": 24,
        "codigo": "M15",
        "banco": "Banco BRJ S.A."
    },
    {
        "id": 25,
        "codigo": "208",
        "banco": "Banco BTG Pactual S.A."
    },
    {
        "id": 26,
        "codigo": "044",
        "banco": "Banco BVA S.A."
    },
    {
        "id": 27,
        "codigo": "263",
        "banco": "Banco Cacique S.A."
    },
    {
        "id": 28,
        "codigo": "473",
        "banco": "Banco Caixa Geral  Brasil S.A."
    },
    {
        "id": 29,
        "codigo": "412",
        "banco": "Banco Capital S.A."
    },
    {
        "id": 30,
        "codigo": "040",
        "banco": "Banco Cargill S.A."
    },
    {
        "id": 31,
        "codigo": "745",
        "banco": "Banco Citibank S.A."
    },
    {
        "id": 32,
        "codigo": "M08",
        "banco": "Banco Citicard S.A."
    },
    {
        "id": 33,
        "codigo": "241",
        "banco": "Banco Clássico S.A."
    },
    {
        "id": 34,
        "codigo": "M19",
        "banco": "Banco CNH Capital S.A."
    },
    {
        "id": 35,
        "codigo": "215",
        "banco": "Banco Comercial e de Investimento Sudameris S.A."
    },
    {
        "id": 36,
        "codigo": "756",
        "banco": "Banco Cooperativo do Brasil S.A. - BANCOOB"
    },
    {
        "id": 37,
        "codigo": "748",
        "banco": "Banco Cooperativo Sicredi S.A."
    },
    {
        "id": 38,
        "codigo": "075",
        "banco": "Banco CR2 S.A."
    },
    {
        "id": 39,
        "codigo": "721",
        "banco": "Banco Credibel S.A."
    },
    {
        "id": 40,
        "codigo": "222",
        "banco": "Banco Credit Agricole Brasil S.A."
    },
    {
        "id": 41,
        "codigo": "505",
        "banco": "Banco Credit Suisse (Brasil) S.A."
    },
    {
        "id": 42,
        "codigo": "229",
        "banco": "Banco Cruzeiro do Sul S.A."
    },
    {
        "id": 43,
        "codigo": "266",
        "banco": "Banco Cédula S.A."
    },
    {
        "id": 44,
        "codigo": "003",
        "banco": "Banco da Amazônia S.A."
    },
    {
        "id": 45,
        "codigo": "0833",
        "banco": "Banco da China Brasil S.A."
    },
    {
        "id": 46,
        "codigo": "M21",
        "banco": "Banco Daimlerchrysler S.A."
    },
    {
        "id": 47,
        "codigo": "707",
        "banco": "Banco Daycoval S.A."
    },
    {
        "id": 48,
        "codigo": "300",
        "banco": "Banco de La Nacion Argentina"
    },
    {
        "id": 49,
        "codigo": "495",
        "banco": "Banco de La Provincia de Buenos Aires"
    },
    {
        "id": 50,
        "codigo": "494",
        "banco": "Banco de La Republica Oriental del Uruguay"
    },
    {
        "id": 51,
        "codigo": "M06",
        "banco": "Banco de Lage Landen Brasil S.A."
    },
    {
        "id": 52,
        "codigo": "024",
        "banco": "Banco de Pernambuco S.A. - BANDEPE"
    },
    {
        "id": 53,
        "codigo": "456",
        "banco": "Banco de TokyoMitsubishi UFJ Brasil S.A."
    },
    {
        "id": 54,
        "codigo": "214",
        "banco": "Banco Dibens S.A."
    },
    {
        "id": 55,
        "codigo": "001",
        "banco": "Banco do Brasil S.A."
    },
    {
        "id": 56,
        "codigo": "047",
        "banco": "Banco do Estado de Sergipe S.A."
    },
    {
        "id": 57,
        "codigo": "037",
        "banco": "Banco do Estado do Pará S.A."
    },
    {
        "id": 58,
        "codigo": "039",
        "banco": "Banco do Estado do Piauí S.A. - BEP"
    },
    {
        "id": 59,
        "codigo": "041",
        "banco": "Banco do Estado do Rio Grande do Sul S.A."
    },
    {
        "id": 60,
        "codigo": "004",
        "banco": "Banco do Nordeste do Brasil S.A."
    },
    {
        "id": 61,
        "codigo": "265",
        "banco": "Banco Fator S.A."
    },
    {
        "id": 62,
        "codigo": "M03",
        "banco": "Banco Fiat S.A."
    },
    {
        "id": 63,
        "codigo": "224",
        "banco": "Banco Fibra S.A."
    },
    {
        "id": 64,
        "codigo": "626",
        "banco": "Banco Ficsa S.A."
    },
    {
        "id": 65,
        "codigo": "M18",
        "banco": "Banco Ford S.A."
    },
    {
        "id": 66,
        "codigo": "233",
        "banco": "Banco GE Capital S.A."
    },
    {
        "id": 67,
        "codigo": "734",
        "banco": "Banco Gerdau S.A."
    },
    {
        "id": 68,
        "codigo": "M07",
        "banco": "Banco GMAC S.A."
    },
    {
        "id": 69,
        "codigo": "612",
        "banco": "Banco Guanabara S.A."
    },
    {
        "id": 70,
        "codigo": "M22",
        "banco": "Banco Honda S.A."
    },
    {
        "id": 71,
        "codigo": "063",
        "banco": "Banco Ibi S.A. Banco Múltiplo"
    },
    {
        "id": 72,
        "codigo": "M11",
        "banco": "Banco IBM S.A."
    },
    {
        "id": 73,
        "codigo": "604",
        "banco": "Banco Industrial do Brasil S.A."
    },
    {
        "id": 74,
        "codigo": "320",
        "banco": "Banco Industrial e Comercial S.A."
    },
    {
        "id": 75,
        "codigo": "653",
        "banco": "Banco Indusval S.A."
    },
    {
        "id": 76,
        "codigo": "630",
        "banco": "Banco Intercap S.A."
    },
    {
        "id": 77,
        "codigo": "0779",
        "banco": "Banco Intermedium S.A."
    },
    {
        "id": 78,
        "codigo": "249",
        "banco": "Banco Investcred Unibanco S.A."
    },
    {
        "id": 79,
        "codigo": "M09",
        "banco": "Banco Itaucred Financiamentos S.A."
    },
    {
        "id": 80,
        "codigo": "184",
        "banco": "Banco Itaú BBA S.A."
    },
    {
        "id": 81,
        "codigo": "479",
        "banco": "Banco ItaúBank S.A"
    },
    {
        "id": 82,
        "codigo": "376",
        "banco": "Banco J. P. Morgan S.A."
    },
    {
        "id": 83,
        "codigo": "074",
        "banco": "Banco J. Safra S.A."
    },
    {
        "id": 84,
        "codigo": "217",
        "banco": "Banco John Deere S.A."
    },
    {
        "id": 85,
        "codigo": "076",
        "banco": "Banco KDB S.A."
    },
    {
        "id": 86,
        "codigo": "757",
        "banco": "Banco KEB do Brasil S.A."
    },
    {
        "id": 87,
        "codigo": "600",
        "banco": "Banco Luso Brasileiro S.A."
    },
    {
        "id": 88,
        "codigo": "212",
        "banco": "Banco Matone S.A."
    },
    {
        "id": 89,
        "codigo": "M12",
        "banco": "Banco Maxinvest S.A."
    },
    {
        "id": 90,
        "codigo": "389",
        "banco": "Banco Mercantil do Brasil S.A."
    },
    {
        "id": 91,
        "codigo": "746",
        "banco": "Banco Modal S.A."
    },
    {
        "id": 92,
        "codigo": "M10",
        "banco": "Banco Moneo S.A."
    },
    {
        "id": 93,
        "codigo": "738",
        "banco": "Banco Morada S.A."
    },
    {
        "id": 94,
        "codigo": "066",
        "banco": "Banco Morgan Stanley S.A."
    },
    {
        "id": 95,
        "codigo": "243",
        "banco": "Banco Máxima S.A."
    },
    {
        "id": 96,
        "codigo": "045",
        "banco": "Banco Opportunity S.A."
    },
    {
        "id": 97,
        "codigo": "M17",
        "banco": "Banco Ourinvest S.A."
    },
    {
        "id": 98,
        "codigo": "623",
        "banco": "Banco Panamericano S.A."
    },
    {
        "id": 99,
        "codigo": "611",
        "banco": "Banco Paulista S.A."
    },
    {
        "id": 100,
        "codigo": "613",
        "banco": "Banco Pecúnia S.A."
    },
    {
        "id": 101,
        "codigo": "0942",
        "banco": "Banco Petra S.A."
    },
    {
        "id": 102,
        "codigo": "643",
        "banco": "Banco Pine S.A."
    },
    {
        "id": 103,
        "codigo": "724",
        "banco": "Banco Porto Seguro S.A."
    },
    {
        "id": 104,
        "codigo": "735",
        "banco": "Banco Pottencial S.A."
    },
    {
        "id": 105,
        "codigo": "638",
        "banco": "Banco Prosper S.A."
    },
    {
        "id": 106,
        "codigo": "M24",
        "banco": "Banco PSA Finance Brasil S.A."
    },
    {
        "id": 107,
        "codigo": "747",
        "banco": "Banco Rabobank International Brasil S.A."
    },
    {
        "id": 108,
        "codigo": "0884",
        "banco": "Banco Randon S.A."
    },
    {
        "id": 109,
        "codigo": "356",
        "banco": "Banco Real S.A."
    },
    {
        "id": 110,
        "codigo": "633",
        "banco": "Banco Rendimento S.A."
    },
    {
        "id": 111,
        "codigo": "741",
        "banco": "Banco Ribeirão Preto S.A."
    },
    {
        "id": 112,
        "codigo": "M16",
        "banco": "Banco Rodobens S.A."
    },
    {
        "id": 113,
        "codigo": "072",
        "banco": "Banco Rural Mais S.A."
    },
    {
        "id": 114,
        "codigo": "453",
        "banco": "Banco Rural S.A."
    },
    {
        "id": 115,
        "codigo": "422",
        "banco": "Banco Safra S.A."
    },
    {
        "id": 116,
        "codigo": "033",
        "banco": "Banco Santander (Brasil) S.A."
    },
    {
        "id": 117,
        "codigo": "250",
        "banco": "Banco Schahin S.A."
    },
    {
        "id": 118,
        "codigo": "743",
        "banco": "Banco Semear S.A."
    },
    {
        "id": 119,
        "codigo": "749",
        "banco": "Banco Simples S.A."
    },
    {
        "id": 120,
        "codigo": "366",
        "banco": "Banco Société Générale Brasil S.A."
    },
    {
        "id": 121,
        "codigo": "637",
        "banco": "Banco Sofisa S.A."
    },
    {
        "id": 122,
        "codigo": "012",
        "banco": "Banco Standard de Investimentos S.A."
    },
    {
        "id": 123,
        "codigo": "464",
        "banco": "Banco Sumitomo Mitsui Brasileiro S.A."
    },
    {
        "id": 124,
        "codigo": "0825",
        "banco": "Banco Topázio S.A."
    },
    {
        "id": 125,
        "codigo": "M20",
        "banco": "Banco Toyota do Brasil S.A."
    },
    {
        "id": 126,
        "codigo": "M13",
        "banco": "Banco Tricury S.A."
    },
    {
        "id": 127,
        "codigo": "634",
        "banco": "Banco Triângulo S.A."
    },
    {
        "id": 128,
        "codigo": "M14",
        "banco": "Banco Volkswagen S.A."
    },
    {
        "id": 129,
        "codigo": "M23",
        "banco": "Banco Volvo (Brasil) S.A."
    },
    {
        "id": 130,
        "codigo": "655",
        "banco": "Banco Votorantim S.A."
    },
    {
        "id": 131,
        "codigo": "610",
        "banco": "Banco VR S.A."
    },
    {
        "id": 132,
        "codigo": "370",
        "banco": "Banco WestLB do Brasil S.A."
    },
    {
        "id": 133,
        "codigo": "021",
        "banco": "BANESTES S.A. Banco do Estado do Espírito Santo"
    },
    {
        "id": 134,
        "codigo": "719",
        "banco": "BanifBanco Internacional do Funchal (Brasil)S.A."
    },
    {
        "id": 135,
        "codigo": "755",
        "banco": "Bank of America Merrill Lynch Banco Múltiplo S.A."
    },
    {
        "id": 136,
        "codigo": "744",
        "banco": "BankBoston N.A."
    },
    {
        "id": 137,
        "codigo": "073",
        "banco": "BB Banco Popular do Brasil S.A."
    },
    {
        "id": 138,
        "codigo": "078",
        "banco": "BES Investimento do Brasil S.A. Banco de Investimento"
    },
    {
        "id": 139,
        "codigo": "069",
        "banco": "BPN Brasil Banco Múltiplo S.A."
    },
    {
        "id": 140,
        "codigo": "070",
        "banco": "BRB  Banco de Brasília S.A."
    },
    {
        "id": 141,
        "codigo": "0922",
        "banco": "Brickell S.A. Crédito, financiamento e Investimento"
    },
    {
        "id": 142,
        "codigo": "104",
        "banco": "Caixa Econômica Federal"
    },
    {
        "id": 143,
        "codigo": "477",
        "banco": "Citibank N.A."
    },
    {
        "id": 144,
        "codigo": "0817",
        "banco": "Concórdia Banco S.A."
    },
    {
        "id": 145,
        "codigo": "0973",
        "banco": "Cooperativa Central de Crédito Noroeste Brasileiro Ltda."
    },
    {
        "id": 146,
        "codigo": "085x",
        "banco": "Cooperativa Central de Crédito UrbanoCECRED"
    },
    {
        "id": 147,
        "codigo": "099x",
        "banco": "Cooperativa Central de Economia e Credito Mutuo das Unicreds"
    },
    {
        "id": 148,
        "codigo": "0902",
        "banco": "Cooperativa Central de Economia e Crédito Mutuo das Unicreds"
    },
    {
        "id": 149,
        "codigo": "0892",
        "banco": "Cooperativa de Crédito Rural da Região de Mogiana"
    },
    {
        "id": 150,
        "codigo": "0876",
        "banco": "Cooperativa Unicred Central Santa Catarina"
    },
    {
        "id": 151,
        "codigo": "0981",
        "banco": "Credicorol Cooperativa de Crédito Rural"
    },
    {
        "id": 152,
        "codigo": "487",
        "banco": "Deutsche Bank S.A. Banco Alemão"
    },
    {
        "id": 153,
        "codigo": "751",
        "banco": "Dresdner Bank Brasil S.A. Banco Múltiplo"
    },
    {
        "id": 154,
        "codigo": "064",
        "banco": "Goldman Sachs do Brasil Banco Múltiplo S.A."
    },
    {
        "id": 155,
        "codigo": "062",
        "banco": "Hipercard Banco Múltiplo S.A."
    },
    {
        "id": 156,
        "codigo": "399",
        "banco": "HSBC Bank Brasil S.A. Banco Múltiplo"
    },
    {
        "id": 157,
        "codigo": "168",
        "banco": "HSBC Finance (Brasil) S.A. Banco Múltiplo"
    },
    {
        "id": 158,
        "codigo": "492",
        "banco": "ING Bank N.V."
    },
    {
        "id": 159,
        "codigo": "652",
        "banco": "Itaú Unibanco Holding S.A."
    },
    {
        "id": 160,
        "codigo": "341",
        "banco": "Itaú Unibanco S.A."
    },
    {
        "id": 161,
        "codigo": "079",
        "banco": "JBS  Banco S.A."
    },
    {
        "id": 162,
        "codigo": "488",
        "banco": "JPMorgan Chase Bank"
    },
    {
        "id": 163,
        "codigo": "014",
        "banco": "Natixis Brasil S.A.Banco Múltiplo"
    },
    {
        "id": 164,
        "codigo": "753",
        "banco": "NBC Bank Brasil S.A. Banco Múltiplo"
    },
    {
        "id": 165,
        "codigo": "0868",
        "banco": "OBOE Crédito Financiamento e Investimento S.A."
    },
    {
        "id": 166,
        "codigo": "254",
        "banco": "Paraná Banco S.A."
    },
    {
        "id": 167,
        "codigo": "409",
        "banco": "UNIBANCO  União de Bancos Brasileiros S.A."
    },
    {
        "id": 168,
        "codigo": "230",
        "banco": "Unicard Banco Múltiplo S.A."
    },
    {
        "id": 169,
        "codigo": "0914",
        "banco": "Unicred Central do Rio Grande do Sul"
    },
    {
        "id": 170,
        "codigo": "084",
        "banco": "Unicred Norte do Paraná"
    },
    {
        "id": 171,
        "codigo": "002",
        "banco": "Banco Central do Brasil"
    },
    {
        "id": 172,
        "codigo": "007",
        "banco": "Banco Nacional de Desenvolvimento Econômico e Social"
    },
    {
        "id": 173,
        "codigo": "046",
        "banco": "Banco Regional de Desenvolvimento do Extremo Sul"
    },
    {
        "id": 174,
        "codigo": "023",
        "banco": "Banco de Desenvolvimento de Minas Gerais"
    },
    {
        "id": 175,
        "codigo": "121",
        "banco": "Agibank"
    },
    {
        "id": 176,
        "codigo": "077",
        "banco": "Banco Inter"
    },
    {
        "id": 177,
        "codigo": "082",
        "banco": "\tBanco Topázio"
    },
    {
        "id": 178,
        "codigo": "260",
        "banco": "Nu Pagamentos S.A"
    },
    {
        "id": 179,
        "codigo": "136",
        "banco": "Unicred"
    }
];