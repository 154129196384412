/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigationViewerShipment: FuseNavigationItem[] = [
    {
        id   : 'manager',
        title: 'Gerenciamento',
        subtitle: 'Gerenciamento de solicitações',
        type : 'group',
        icon : 'heroicons_outline:cog',
        children : [
            {
                id   : 'order',
                title: 'Frete',
                subtitle: 'Acompanhamento de frete',
                type : 'group',
                icon : 'heroicons_outline:clipboard-check',
                children: [
                    {
                        id   : 'order.view-frete',
                        title: 'Acompanhamento de frete',
                        type : 'basic',
                        icon : 'mat_outline:dvr',
                        link : '/leilao/embarcador'
                    }

                ]
            }
        ]
    }

];
export const compactNavigationViewerShipment: FuseNavigationItem[] = [
    {
        id   : 'manager',
        title: 'Gerenciamento',
        subtitle: 'Gerenciamento de solicitações',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
export const futuristicNavigationViewerShipment: FuseNavigationItem[] = [
    {
        id   : 'manager',
        title: 'Controles',
        subtitle: 'Gerenciamento de solicitações',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
export const horizontalNavigationViewerShipment: FuseNavigationItem[] = [
    {
        id   : 'manager',
        title: 'Gerenciamento',
        subtitle: 'Gerenciamento de solicitações',
        type : 'aside',
        icon : 'heroicons_outline:cog',
        children : []
    }
];
