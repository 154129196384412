export const MODELO_RASTREADOR = {
    "AUTOTRAC": [
        {"codigo": 8955, "modelo": "AUTOTRAC HÍBRIDO"},
        {"codigo": 8954, "modelo": "AUTOTRAC CAMINHONEIRO"},
        {"codigo": 8953, "modelo": "AUTOTRAC CELULAR"},
        {"codigo": 8952, "modelo": "AUTOTRAC SATELITE"},
        {"codigo": 8951, "modelo": "AUTOTRAC PRIME"}
    ],
    "SASCAR": [
        {"codigo": 8950, "modelo": "SASCAR GPRS"},
        {"codigo": 8949, "modelo": "SASMDT SAT COM TELEMETRIA"},
        {"codigo": 8948, "modelo": "SASCARGA FULL SAT III"},
        {"codigo": 8947, "modelo": "SASTM FULL SAT 200"}
    ],
    "ONIXSAT": [
        {"codigo": 8946, "modelo": "ONIXSMART2 GPRS"},
        {"codigo": 8945, "modelo": "ONIXSMART2 - CONECTIVIDADE"},
        {"codigo": 8944, "modelo": "ONIXSMARTMID HIBRIDO"},
        {"codigo": 8943, "modelo": "ONIXSMART2 HÍBRIDO"}       
    ],
    "VELTRAC": [
        {"codigo": 8930, "modelo": "VELTEC HÍBRIDO - HÍBRIDO"},
        {"codigo": 8929, "modelo": "VELTEC GSM - GPRS"},
    ],
    "OMNILINK": [
        {"codigo": 8914, "modelo": "OMNILINK 4484 - HÍBRIDO"},
        {"codigo": 8913, "modelo": "OMNILINK LINKER - GPRS"},
        {"codigo": 8912, "modelo": "OMNILINK 4454 - GPRS"},
    ]
}