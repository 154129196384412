import { Injectable } from "@angular/core";
import { FuseMockApiService } from "@fuse/lib/mock-api";
import { cloneDeep } from "lodash";
import { ISCAS } from "./data";

@Injectable({
    providedIn: 'root'
})
export class IscasApi
{
    private _iscas: any = ISCAS;

    constructor( private _fuseMockApiService: FuseMockApiService )
    {
        this.registerHandlers();
    }

    registerHandlers(): void {

        this._fuseMockApiService.onGet('api/common/veiculo/iscas').reply(() => [200, cloneDeep(this._iscas)]);

    }
}