"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var Subscriber_1 = require("./Subscriber");
var Observable_1 = require("./Observable");
var subscribeTo_1 = require("./util/subscribeTo");
var SimpleInnerSubscriber = function (_super) {
  __extends(SimpleInnerSubscriber, _super);
  function SimpleInnerSubscriber(parent) {
    var _this = _super.call(this) || this;
    _this.parent = parent;
    return _this;
  }
  SimpleInnerSubscriber.prototype._next = function (value) {
    this.parent.notifyNext(value);
  };
  SimpleInnerSubscriber.prototype._error = function (error) {
    this.parent.notifyError(error);
    this.unsubscribe();
  };
  SimpleInnerSubscriber.prototype._complete = function () {
    this.parent.notifyComplete();
    this.unsubscribe();
  };
  return SimpleInnerSubscriber;
}(Subscriber_1.Subscriber);
exports.SimpleInnerSubscriber = SimpleInnerSubscriber;
var ComplexInnerSubscriber = function (_super) {
  __extends(ComplexInnerSubscriber, _super);
  function ComplexInnerSubscriber(parent, outerValue, outerIndex) {
    var _this = _super.call(this) || this;
    _this.parent = parent;
    _this.outerValue = outerValue;
    _this.outerIndex = outerIndex;
    return _this;
  }
  ComplexInnerSubscriber.prototype._next = function (value) {
    this.parent.notifyNext(this.outerValue, value, this.outerIndex, this);
  };
  ComplexInnerSubscriber.prototype._error = function (error) {
    this.parent.notifyError(error);
    this.unsubscribe();
  };
  ComplexInnerSubscriber.prototype._complete = function () {
    this.parent.notifyComplete(this);
    this.unsubscribe();
  };
  return ComplexInnerSubscriber;
}(Subscriber_1.Subscriber);
exports.ComplexInnerSubscriber = ComplexInnerSubscriber;
var SimpleOuterSubscriber = function (_super) {
  __extends(SimpleOuterSubscriber, _super);
  function SimpleOuterSubscriber() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  SimpleOuterSubscriber.prototype.notifyNext = function (innerValue) {
    this.destination.next(innerValue);
  };
  SimpleOuterSubscriber.prototype.notifyError = function (err) {
    this.destination.error(err);
  };
  SimpleOuterSubscriber.prototype.notifyComplete = function () {
    this.destination.complete();
  };
  return SimpleOuterSubscriber;
}(Subscriber_1.Subscriber);
exports.SimpleOuterSubscriber = SimpleOuterSubscriber;
var ComplexOuterSubscriber = function (_super) {
  __extends(ComplexOuterSubscriber, _super);
  function ComplexOuterSubscriber() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  ComplexOuterSubscriber.prototype.notifyNext = function (_outerValue, innerValue, _outerIndex, _innerSub) {
    this.destination.next(innerValue);
  };
  ComplexOuterSubscriber.prototype.notifyError = function (error) {
    this.destination.error(error);
  };
  ComplexOuterSubscriber.prototype.notifyComplete = function (_innerSub) {
    this.destination.complete();
  };
  return ComplexOuterSubscriber;
}(Subscriber_1.Subscriber);
exports.ComplexOuterSubscriber = ComplexOuterSubscriber;
function innerSubscribe(result, innerSubscriber) {
  if (innerSubscriber.closed) {
    return undefined;
  }
  if (result instanceof Observable_1.Observable) {
    return result.subscribe(innerSubscriber);
  }
  var subscription;
  try {
    subscription = subscribeTo_1.subscribeTo(result)(innerSubscriber);
  } catch (error) {
    innerSubscriber.error(error);
  }
  return subscription;
}
exports.innerSubscribe = innerSubscribe;
